<!-- eslint-disable vue/no-v-html -->
<template>
  <swiper-slide class="relative" data-testid="Slide">
    <component
      :is="data.url?.length ? 'a' : 'div'"
      class="absolute inset-0 flex h-full w-full justify-center"
      v-bind="{
        ...(data.url?.length ? { href: data.url, target: '_blank' } : {})
      }"
      @click="handleClick"
    >
      <div
        class="align-center container absolute inset-0 z-50 mx-auto flex h-full w-full"
        :class="
          withCounterButton ? 'xl:justify-center xl:pl-[calc(350px+2rem)]' : ''
        "
      >
        <div
          class="content-wrapper relative z-[9999] flex h-full max-h-[100%-8px] w-full flex-col justify-center gap-4"
          :class="{
            'pb-2': showThumbs
          }"
        >
          <div
            class="content title prose-sm relative z-50 flex w-fit max-w-none flex-col justify-center gap-4 break-words md:prose-base lg:prose-lg xl:prose-2xl md:flex"
            v-html="data.title.trim()"
          />
          <div
            class="content description prose-sm relative z-50 hidden w-fit max-w-[calc(100%-48px)] flex-col gap-4 break-words md:prose-base md:flex"
            v-html="addBlankToExternalLinks(data.description.trim(), host)"
          />
          <div
            v-if="data.auctionDescription"
            id="auctionDescription"
            class="content description prose-sm relative z-50 flex w-max max-w-none flex-col gap-4 break-words md:prose-base"
            :class="data.auctionDescriptionAlign === 'right' ? 'ml-auto' : ''"
            v-html="
              addBlankToExternalLinks(data.auctionDescription.trim(), host)
            "
          />
        </div>
      </div>
      <StrapiImage
        v-if="data.slide.mime.includes('image')"
        :key="data.slide.url"
        :loading="isLazyLoading ? 'lazy' : 'eager'"
        :data="data.slide"
        class="h-full w-full object-cover object-[70%_20%] sm:object-[35%_20%] md:block md:object-[70%_20%] lg:object-[65%_20%] xl:object-center"
        :class="{
          hidden: data.slideMobile
        }"
      />
      <StrapiImage
        v-if="data.slideMobile?.mime.includes('image')"
        :key="data.slideMobile.url"
        :loading="isLazyLoading ? 'lazy' : 'eager'"
        :data="data.slideMobile"
        class="h-full w-full object-cover object-[70%_20%] sm:object-[35%_20%] md:hidden"
      />
      <div v-if="isLazyLoading" class="swiper-lazy-preloader"></div>

      <video
        v-if="data.slide.mime.includes('video')"
        data-testid="video"
        autoplay
        muted
        loop
        playsinline
        class="h-full w-full object-cover md:block"
        :class="{
          hidden: data.slideMobile
        }"
      >
        <source :src="`${_url}${data.slide.url}`" :type="data.slide.mime" />
      </video>
      <video
        v-if="data.slideMobile?.mime.includes('video')"
        data-testid="video"
        autoplay
        loop
        muted
        playsinline
        class="h-full w-full object-cover md:hidden"
      >
        <source
          :src="`${_url}${data.slideMobile.url}`"
          :type="data.slideMobile.mime"
        />
      </video>
    </component>
  </swiper-slide>
</template>
<script setup lang="ts">
import { useCmsConfig } from '@autobid/strapi-integration/composable/useCmsConfig'
import type { FlattedSlideData } from '@autobid/ui/composables/useSlides'
import StrapiImage from '@autobid/ui/components/common/strapi/Image.vue'
import { trackEvent } from '@autobid/tracking/src/utils/trackEvent'
import type { MatomoEvent } from '@autobid/tracking/src/types/matomo'
import { addBlankToExternalLinks } from '@autobid/ui/utils/addBlankToExternalLinks'

interface Props {
  data: {
    id: string
    slide: FlattedSlideData[number]['slide']
    slideMobile?: FlattedSlideData[number]['slideMobile']
    auctionDescriptionAlign?: FlattedSlideData[number]['auctionDescriptionAlign']
    description: string
    auctionDescription?: string
    title: string
    url?: string
    matomo?: MatomoEvent
  }
  withCounterButton?: boolean
  showThumbs?: boolean
  index: number
}

const props = defineProps<Props>()

const isLazyLoading = props.index > 0

const { url: _url } = useCmsConfig()
const { SELF_URL } = useRuntimeConfig().public
const { host } = new URL(SELF_URL)

const handleClick = () => {
  if (!props.data.url?.length) return

  trackEvent(props.data.matomo)
}
</script>
